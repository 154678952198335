<template>
  <div>
    <b-row>
      <b-col>
        <customer-table />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';
import CustomerTable from '@/layouts/components/tables/CustomerTable.vue';

export default {
  components: {
    BRow,
    BCol,
    CustomerTable,
  },
};
</script>

<style></style>
