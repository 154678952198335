<template>
  <b-card title="Kunden" no-body>
    <b-card-header>
      <div class="d-flex align-items-center">
        <feather-icon size="24" class="mr-1" icon="BriefcaseIcon" />
        <b-card-title class="ml-25"> Kunden </b-card-title>
      </div>
    </b-card-header>
    <div class="mx-2 mb-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label class="mb-0">Zeige</label>
          <v-select
            v-model="perPage"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label class="mb-0">Kunden</label>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="filters.searchQuery"
              class="d-inline-block mr-1"
              placeholder="Suchen..."
            />
            <b-button variant="primary" v-b-modal.modal-add-customer>
              <span class="text-nowrap">Kunde hinzufügen</span>
            </b-button>
            <add-customer-modal @newCustomerAdded="refetchTable" />
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-table
        :key="tableKey"
        :items="customerItemsProvider"
        :fields="fields"
        :per-page="0"
        :current-page="currentPage"
        :filter="filters"
        sort-by="Firma"
        striped
        responsive
        class="mb-0"
      >
        <!-- Column: Actions -->
        <template #cell(Aktionen)="row">
          <div class="text-nowrap">
            <feather-icon
              :id="`customer-row-${row.item.id}-preview-icon`"
              icon="EyeIcon"
              size="20"
              class="mr-50 cursor-pointer text-primary"
              @click="row.toggleDetails"
            />
            <b-tooltip
              title="Infos einblenden"
              class="cursor-pointer"
              :target="`customer-row-${row.item.id}-preview-icon`"
            />
            <feather-icon
              :id="`customer-row-${row.item.id}-details-icon`"
              icon="FileTextIcon"
              size="20"
              class="mx-50 cursor-pointer text-primary"
              @click="
                $router.push({
                  name: 'kunde-ansicht',
                  params: { id: row.item.id },
                })
              "
            />
            <b-tooltip
              title="Details"
              class="cursor-pointer"
              :target="`customer-row-${row.item.id}-details-icon`"
            />
            <feather-icon
              :id="`customer-row-${row.item.id}-edit-icon`"
              icon="EditIcon"
              size="20"
              class="mx-50 cursor-pointer text-primary"
              @click="
                $router.push({
                  name: 'kunde-bearbeiten',
                  params: { id: row.item.id },
                })
              "
            />
            <b-tooltip
              title="Bearbeiten"
              class="cursor-pointer"
              :target="`customer-row-${row.item.id}-edit-icon`"
            />
            <feather-icon
              :id="`customer-row-${row.item.id}-delete-icon`"
              icon="TrashIcon"
              size="20"
              class="mx-50 cursor-pointer text-danger"
              v-b-modal:[`modal-delete-customer-${row.item.id}`]
            />
            <b-tooltip
              title="Löschen"
              class="cursor-pointer"
              :target="`customer-row-${row.item.id}-delete-icon`"
            />
            <delete-customer-modal
              :userData="row.item"
              @customerDeleted="refetchTable"
            />
          </div>
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <b-card class="details-card">
            <h4 class="mb-1">Allgemein</h4>
            <b-row class="mx-1">
              <b-col md="4" class="mb-1">
                <strong>Firma: </strong>{{ row.item.Firma }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Webseite: </strong
                ><a :href="row.item.Webseite" target="_blank">{{
                  row.item.Webseite
                }}</a>
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>USt-ID: </strong>{{ row.item.USt_ID }}
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <h4 class="mb-1">Kontakt</h4>
            <b-row class="mx-1">
              <b-col md="4" class="mb-1">
                <strong>Email: </strong
                ><a :href="`mailto:${row.item.User.email}`" target="_blank">{{
                  row.item.User.email
                }}</a>
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Telefon: </strong
                ><a :href="`tel:${row.item.Kontakt.Telefon}`" target="_blank">{{
                  row.item.Kontakt.Telefon
                }}</a>
              </b-col>
              <b-col md="4" class="mb-1" v-if="row.item.Kontakt.Mobil">
                <strong>Mobil: </strong
                ><a :href="`tel:${row.item.Kontakt.Mobil}`" target="_blank">{{
                  row.item.Kontakt.Mobil
                }}</a>
              </b-col>
              <b-col md="4" class="mb-1" v-if="row.item.Kontakt.Fax">
                <strong>Fax: </strong
                ><a :href="`tel:${row.item.Kontakt.Fax}`" target="_blank">{{
                  row.item.Kontakt.Fax
                }}</a>
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <h4 class="mb-1">Anschrift</h4>
            <b-row class="mx-1">
              <b-col md="4" class="mb-1">
                <strong>Straße: </strong
                >{{
                  `${row.item.Anschrift.Strasse} ${row.item.Anschrift.Hausnummer}`
                }}
              </b-col>
              <b-col md="4" class="mb-1" v-if="row.item.Anschrift.Zusatz">
                <strong>Adresszusatz: </strong
                >{{ `${row.item.Anschrift.Zusatz}` }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>PLZ: </strong>{{ row.item.Anschrift.PLZ }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Stadt: </strong>{{ row.item.Anschrift.Stadt }}
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <h4 class="mb-1" v-if="row.item.Rechnungsanschrift">
              Rechnungsanschrift
            </h4>
            <b-row v-if="row.item.Rechnungsanschrift" class="mx-1">
              <b-col md="4" class="mb-1">
                <strong>Straße: </strong
                >{{
                  `${row.item.Rechnungsanschrift.Strasse} ${row.item.Rechnungsanschrift.Hausnummer}`
                }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
                v-if="row.item.Rechnungsanschrift.Zusatz"
              >
                <strong>Adresszusatz: </strong
                >{{ `${row.item.Rechnungsanschrift.Zusatz}` }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>PLZ: </strong>{{ row.item.Rechnungsanschrift.PLZ }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Stadt: </strong>{{ row.item.Rechnungsanschrift.Stadt }}
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" v-if="row.item.Rechnungsanschrift" />

            <h4 class="mb-1">Ansprechpartner</h4>
            <b-row class="mx-1">
              <b-col
                md="4"
                class="mb-1"
                v-for="ansprechpartner in row.item.Ansprechpartner"
                :key="ansprechpartner.id"
              >
                <b-card
                  class="mb-0 details-card"
                  :class="{ inactive: ansprechpartner.Inaktiv }"
                  ><b-row>
                    <b-col cols="12" class="mb-1">
                      <strong>Name: </strong>{{ ansprechpartner.Vorname }}
                      {{ ansprechpartner.Nachname }}
                    </b-col>
                    <b-col cols="12" class="mb-1">
                      <strong>Abteilung: </strong
                      >{{ ansprechpartner.Abteilung }}
                    </b-col>
                    <b-col cols="12" class="mb-1">
                      <strong>Position: </strong>{{ ansprechpartner.Position }}
                    </b-col>
                    <b-col cols="12" class="mb-1">
                      <strong>Email: </strong
                      ><a
                        :href="`mailto:${ansprechpartner.Email}`"
                        target="_blank"
                        >{{ ansprechpartner.Email }}</a
                      >
                    </b-col>
                    <b-col cols="12" class="mb-1">
                      <strong>Telefon: </strong
                      ><a :href="`tel:${ansprechpartner.Telefon}`">{{
                        ansprechpartner.Telefon
                      }}</a>
                    </b-col>
                    <b-col cols="12" class="mb-1">
                      <strong>Mobil: </strong
                      ><a :href="`tel:${ansprechpartner.Mobil}`">{{
                        ansprechpartner.Mobil
                      }}</a>
                    </b-col>
                    <b-col md="12" class="d-flex">
                      <strong>Inaktiv: </strong>
                      <b-form-checkbox
                        class="ml-1"
                        :checked="ansprechpartner.Inaktiv"
                        disabled
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <h4 v-if="row.item.Sonstiges" class="mb-1">Sonstiges</h4>
            <b-row v-if="row.item.Sonstiges" class="mx-1">
              <b-col md="4" class="mb-1">
                <strong>Zahlungsziel: </strong
                >{{ `${row.item.Zahlungsziel} Tage` }}
              </b-col>
              <b-col class="mb-1">
                <strong>Sonstige Informationen: </strong>
                {{ row.item.Sonstiges }}
              </b-col>
            </b-row>
            <hr v-if="row.item.Sonstiges" class="mt-0 mb-2" />

            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Details ausblenden
            </b-button>
          </b-card>
        </template>

        <!-- Column: Kundennummer -->
        <template #cell(Kundennummer)="data">
          <b-link
            :to="{
              name: 'kunde-ansicht',
              params: { id: data.item.id },
            }"
            >#{{ data.item.Kundennummer }}</b-link
          >
        </template>

        <!-- Column: Customer -->
        <template #cell(Firma)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                class="kunde-avatar"
                size="40"
                :src="
                  data.item.User.avatar
                    ? $dbBaseUrl + data.item.User.avatar.formats.thumbnail.url
                    : ''
                "
                :text="`${data.item.Firma.charAt()}`"
                :variant="`light-secondary`"
                :to="{
                  name: 'kunde-ansicht',
                  params: { id: data.item.id },
                }"
                rounded
              />
            </template>
            <b-link
              :to="{
                name: 'kunde-ansicht',
                params: { id: data.item.id },
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.Firma }}
            </b-link>
            <small class="text-muted">{{ data.item.User.email }}</small>
          </b-media>
        </template>

        <!-- Column: Contact Persons -->
        <template #cell(Ansprechpartner)="data">
          {{ contactPersonsSeparated(data.item.Ansprechpartner) }}
        </template>

        <template #cell(Bewertung)="data">
          <b-badge :variant="rating[1][data.item.Bewertung]">
            {{ rating[0][data.item.Bewertung] }}
          </b-badge>
        </template>
      </b-table>
    </div>
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Zeigt Kunde {{ dataMeta.from }} bis {{ dataMeta.to }} von
            {{ dataMeta.of }} Kunden</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BCardHeader,
  BCardTitle,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BMedia,
  BLink,
  BBadge,
  BPagination,
  BFormInput,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import AddCustomerModal from '@/layouts/components/modals/AddCustomerModal.vue';
import DeleteCustomerModal from '@/layouts/components/modals/DeleteCustomerModal.vue';

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCardHeader,
    BCardTitle,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BMedia,
    BLink,
    BPagination,
    BFormInput,
    BTooltip,
    vSelect,
    AddCustomerModal,
    DeleteCustomerModal,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50, 100],
      totalRows: 0,
      tableKey: 1,
      filters: { searchQuery: '' },
      fields: [
        { key: 'Kundennummer', label: 'Kundennummer', sortable: true },
        { key: 'Firma', label: 'Firma', sortable: true },
        { key: 'Ansprechpartner', label: 'Ansprechpartner' },
        { key: 'Bewertung', label: 'Bewertung', sortable: true },
        { key: 'Aktionen', label: 'Aktionen' },
      ],

      /* eslint-disable global-require */
      rating: [
        {
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
          6: '6',
        },
        {
          1: 'success',
          2: 'primary',
          3: 'info',
          4: 'secondary',
          5: 'warning',
          6: 'danger',
        },
      ],
    };
  },
  computed: {
    dataMeta() {
      const fromItems = this.perPage * (this.currentPage - 1) + 1;
      const toItems = this.perPage * (this.currentPage - 1) + this.perPage;

      return {
        from: fromItems,
        to:
          toItems < this.totalRows
            ? toItems
            : toItems - (toItems - this.totalRows),
        of: this.totalRows,
      };
    },
  },
  watch: {
    perPage(newValue, oldValue) {
      if (newValue !== oldValue) this.refetchTable();
    },
  },
  methods: {
    refetchTable() {
      this.tableKey += 1;
    },
    contactPersonsSeparated(contactPersons) {
      let contactPersonString = '';
      contactPersons.forEach(contactPerson => {
        contactPersonString += `${contactPerson.Vorname} ${contactPerson.Nachname}, `;
      });
      return contactPersonString.slice(0, contactPersonString.length - 2);
    },
    async customerItemsProvider(ctx) {
      switch (true) {
        case ctx.sortBy === '':
          ctx.sortBy = 'Kundennummer';
          break;
        default:
          break;
      }
      const qs = require('qs');
      const query = qs.stringify(
        {
          pagination: {
            page: ctx.currentPage,
            pageSize: this.perPage,
          },
          sort: [`${ctx.sortBy}:${ctx.sortDesc ? 'desc' : 'asc'}`],
          filters: {
            $or: [
              {
                Kundennummer: {
                  $containsi: ctx.filter.searchQuery,
                },
              },
              {
                Firma: {
                  $containsi: ctx.filter.searchQuery,
                },
              },
              {
                Bewertung: {
                  $containsi: ctx.filter.searchQuery,
                },
              },
            ],
          },
          populate: [
            'Auftraege',
            'Anschrift',
            'Rechnungsanschrift',
            'Kontakt',
            'Ansprechpartner',
            'User.avatar',
          ],
        },
        {
          encodeValuesOnly: true,
        }
      );

      let customerItems = [];

      await this.$http
        .get(`/kunden?${query}`)
        .then(response => {
          customerItems = response.data.data;
          this.totalRows = response.data.meta.pagination.total;
        })
        .catch(err => console.log(err.response.data.error.message));
      return customerItems;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.kunde-avatar .b-avatar-img img {
  object-fit: contain;
}
.per-page-selector {
  min-width: 6rem;
}
.card.details-card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
.card.inactive {
  background-color: #f0f0f0;
}
</style>
