<template>
  <b-modal
    id="modal-add-customer"
    centered
    title="Kunde hinzufügen"
    ok-title="Hinzufügen"
    cancel-title="Abbrechen"
    cancel-variant="outline-secondary"
    @ok="addCustomer"
    @cancel="initializeCustomerData"
    @close="initializeCustomerData"
  >
    <!-- Form -->
    <validation-observer ref="registerCustomerForm" #default="{ invalid }">
      <b-form class="auth-register-form mt-2" @submit.prevent="register">
        <b-card-title class="mb-1">Anmeldedaten</b-card-title>

        <!-- Username -->
        <b-form-group label="Benutzername*" label-for="register-username">
          <validation-provider
            #default="{ errors }"
            name="Username"
            vid="username"
            rules="required|min:5"
          >
            <b-form-input
              id="register-username"
              v-model="userData.username"
              name="register-username"
              :state="errors.length > 0 ? false : null"
              placeholder="maxmustermann"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="userIdNotUnique" class="text-danger"
              >Benutzername bereits vergeben</small
            >
          </validation-provider>
        </b-form-group>

        <!-- Email -->
        <b-form-group label="Email*" label-for="register-email">
          <validation-provider
            #default="{ errors }"
            name="Email"
            vid="email"
            rules="required|email"
          >
            <b-form-input
              id="register-email"
              v-model="userData.email"
              name="register-email"
              :state="errors.length > 0 ? false : null"
              placeholder="firma@temporatio.de"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="emailNotUnique" class="text-danger"
              >Email-Adresse bereits vergeben</small
            >
          </validation-provider>
        </b-form-group>

        <!-- Passwort -->
        <b-form-group label-for="register-password" label="Passwort*">
          <validation-provider
            #default="{ errors }"
            name="Password"
            vid="password"
            rules="required|min:6"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="register-password"
                v-model="userData.password"
                class="form-control-merge"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false : null"
                name="register-password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <hr />

        <b-card-title class="mb-1">Grunddaten</b-card-title>

        <!-- Avatar-Bild -->
        <b-form-group label-for="picture-upload" label="Benutzerbild hochladen">
          <div class="d-flex">
            <b-form-file
              id="picture-upload"
              v-model="image"
              accept="image/*"
              placeholder="Datei auswählen ..."
              drop-placeholder="Datei hierher ziehen ..."
            />
            <b-button
              v-if="hasImage"
              variant="danger"
              class="ml-1 text-nowrap"
              @click="clearImage"
              >Bild löschen</b-button
            >
          </div>
          <b-img
            v-if="hasImage"
            :src="imageSrc"
            class="mt-1 rounded-circle mx-auto avatar-preview"
            fluid
            block
          ></b-img>
        </b-form-group>

        <b-row>
          <!-- Firma -->
          <b-col md="6">
            <b-form-group label="Firma*" label-for="register-firma">
              <validation-provider
                #default="{ errors }"
                name="Firma"
                vid="firma"
                rules="required"
              >
                <b-form-input
                  id="register-firma"
                  v-model="customerData.Firma"
                  name="register-firma"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Firma"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Kundennummer -->
          <b-col md="6">
            <b-form-group
              label="Kundennummer*"
              label-for="register-kundennummer"
            >
              <validation-provider
                #default="{ errors }"
                name="Kundennummer"
                vid="kundennummer"
                rules="required|min:5"
              >
                <b-form-input
                  id="register-kundennummer"
                  v-model="customerData.Kundennummer"
                  name="register-kundennummer"
                  placeholder="12345"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="customerIdNotUnique" class="text-danger"
                  >Kundennummer bereits vergeben</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- USt.-ID -->
          <b-col md="12">
            <b-form-group label="USt.-ID" label-for="register-ust-id">
              <validation-provider
                #default="{ errors }"
                name="USt.-ID"
                vid="ust-id"
              >
                <b-form-input
                  id="register-ust-id"
                  v-model="customerData.USt_ID"
                  name="register-ust-id"
                  :state="errors.length > 0 ? false : null"
                  placeholder="DE123456789"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Webseite -->
          <b-col md="12">
            <b-form-group label="Webseite" label-for="register-webseite">
              <validation-provider
                #default="{ errors }"
                name="Webseite"
                vid="webseite"
              >
                <b-form-input
                  id="register-webseite"
                  v-model="customerData.Webseite"
                  name="register-webseite"
                  :state="errors.length > 0 ? false : null"
                  placeholder="https://firma.de"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <hr />

        <b-card-title class="mb-1">Anschrift</b-card-title>
        <b-row>
          <b-col md="6">
            <!-- Straße -->
            <b-form-group label="Straße" label-for="register-strasse">
              <b-form-input
                id="register-strasse"
                v-model="customerData.Anschrift.Strasse"
                name="register-strasse"
                placeholder="Musterstraße"
              />
            </b-form-group>
          </b-col>

            <!-- Hausnummer -->
          <b-col md="6">
            <b-form-group label="Hausnummer" label-for="register-hausnummer">
              <b-form-input
                id="register-hausnummer"
                v-model="customerData.Anschrift.Hausnummer"
                name="register-hausnummer"
                placeholder="26"
              />
            </b-form-group>
          </b-col>

          
             <!-- Adresszusatz -->
          <b-col md="6">
            <b-form-group label="Adresszusatz" label-for="register-adresszusatz">
              <b-form-input
                id="register-register-adresszusatz"
                v-model="customerData.Anschrift.Zusatz"
                name="register-adresszusatz"
                placeholder="Postfach"
              />
            </b-form-group>
          </b-col>

          <!-- PLZ -->
          <b-col md="6">
            <b-form-group label="PLZ" label-for="register-plz">
              <b-form-input
                id="register-plz"
                v-model="customerData.Anschrift.PLZ"
                name="register-plz"
                placeholder="12345"
              />
            </b-form-group>
          </b-col>

            <!-- Stadt -->
          <b-col md="6">
            <b-form-group label="Stadt" label-for="register-stadt">
              <b-form-input
                id="register-stadt"
                v-model="customerData.Anschrift.Stadt"
                name="register-stadt"
                placeholder="Heidelberg"
              />
            </b-form-group>
          </b-col>

          <!-- Rechnungsaddresse -->
          <b-col md="12">
            <b-form-checkbox
              v-model="invoiceAddress"
              name="check-button"
              switch
              inline
            >
              Abweichende Rechnungsanschrift?
            </b-form-checkbox>
          </b-col>
        </b-row>
        <hr />

        <div v-if="invoiceAddress">
          <b-card-title class="mb-1">Rechnungsanschrift</b-card-title>
          <b-row>
              <!-- Straße -->
            <b-col md="6">
              <b-form-group
                label="Straße"
                label-for="register-rechnung-strasse"
              >
                <b-form-input
                  id="register-rechnung-strasse"
                  v-model="customerData.Rechnungsanschrift.Strasse"
                  name="register-rechnung-strasse"
                  placeholder="Musterstraße"
                />
              </b-form-group>
            </b-col>

              <!-- Hausnummer -->
            <b-col md="6">
              <b-form-group
                label="Hausnummer"
                label-for="register-rechnung-hausnummer"
              >
                <b-form-input
                  id="register-rechnung-hausnummer"
                  v-model="customerData.Rechnungsanschrift.Hausnummer"
                  name="register-rechnung-hausnummer"
                  placeholder="26"
                />
              </b-form-group>
            </b-col>

             <!-- Adresszusatz -->
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Adresszusatz"
                label-for="register-rechnung-adresszusatz"
              >
                <b-form-input
                  id="register-rechnung-adresszusatz"
                  v-model="customerData.Rechnungsanschrift.Zusatz"
                  name="register-rechnung-adresszusatz"
                  placeholder="Postfach"
                />
              </b-form-group>
            </b-col>

            <!-- PLZ -->
            <b-col md="6">
              <b-form-group label="PLZ" label-for="register-rechnung-plz">
                <b-form-input
                  id="register-rechnung-plz"
                  v-model="customerData.Rechnungsanschrift.PLZ"
                  name="register-rechnung-plz"
                  placeholder="12345"
                />
              </b-form-group>
            </b-col>
            
              <!-- Stadt -->
            <b-col md="6">
              <b-form-group label="Stadt" label-for="register-rechnung-stadt">
                <b-form-input
                  id="register-rechnung-stadt"
                  v-model="customerData.Rechnungsanschrift.Stadt"
                  name="register-rechnung-stadt"
                  placeholder="Heidelberg"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
        </div>

        <b-card-title class="mb-1">Kontakt</b-card-title>
        <b-row>
          <b-col md="6">
            <!-- Telefon -->
            <b-form-group label="Telefon" label-for="register-telefon">
              <b-form-input
                id="register-telefon"
                v-model="customerData.Kontakt.Telefon"
                name="register-telefon"
                placeholder="12345 12345678"
              />
            </b-form-group>
          </b-col>
          <!-- Mobil -->
          <b-col md="6">
            <b-form-group label="Mobil" label-for="register-mobil">
              <b-form-input
                id="register-mobil"
                v-model="customerData.Kontakt.Mobil"
                name="register-mobil"
                placeholder="12345 12345678"
              />
            </b-form-group>
          </b-col>
          <!-- Fax -->
          <b-col md="6">
            <b-form-group label="Fax" label-for="register-fax">
              <b-form-input
                id="register-fax"
                v-model="customerData.Kontakt.Fax"
                name="register-fax"
                placeholder="12345 12345678"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr />

        <b-card-title class="mb-1">Ansprechpartner</b-card-title>
        <b-row>
          <!-- Ansprechpartner Vorname -->
          <b-col md="6">
            <b-form-group
              label="Vorname*"
              label-for="register-ansprechpartner-vorname"
            >
              <validation-provider
                #default="{ errors }"
                name="Vorname"
                vid="vorname"
                rules="required"
              >
                <b-form-input
                  id="register-ansprechpartner-vorname"
                  v-model="customerData.Ansprechpartner[0].Vorname"
                  name="register-ansprechpartner-vorname"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Max"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Ansprechpartner Nachname -->
          <b-col md="6">
            <b-form-group
              label="Nachname*"
              label-for="register-ansprechpartner-nachname"
            >
              <validation-provider
                #default="{ errors }"
                name="Nachname"
                vid="nachname"
                rules="required"
              >
                <b-form-input
                  id="register-ansprechpartner-nachname"
                  v-model="customerData.Ansprechpartner[0].Nachname"
                  name="register-ansprechpartner-nachname"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Mustermann"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Ansprechpartner Abteilung -->
          <b-col md="12">
            <b-form-group
              label="Abteilung"
              label-for="register-ansprechpartner-abteilung"
            >
              <validation-provider
                #default="{ errors }"
                name="Abteilung"
                vid="abteilung"
              >
                <b-form-input
                  id="register-ansprechpartner-abteilung"
                  v-model="customerData.Ansprechpartner[0].Abteilung"
                  name="register-ansprechpartner-abteilung"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Abteilung"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Ansprechpartner Position -->
          <b-col md="12">
            <b-form-group
              label="Position"
              label-for="register-ansprechpartner-position"
            >
              <validation-provider
                #default="{ errors }"
                name="Position"
                vid="position"
              >
                <b-form-input
                  id="register-ansprechpartner-position"
                  v-model="customerData.Ansprechpartner[0].Position"
                  name="register-ansprechpartner-position"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Position"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Ansprechpartner Telefon -->
          <b-col md="12">
            <b-form-group
              label="Telefon"
              label-for="register-ansprechpartner-telefon"
            >
              <validation-provider
                #default="{ errors }"
                name="Telefon"
                vid="telefon"
              >
                <b-form-input
                  id="register-ansprechpartner-Telefon"
                  v-model="customerData.Ansprechpartner[0].Telefon"
                  name="register-ansprechpartner-telefon"
                  :state="errors.length > 0 ? false : null"
                  placeholder="1234 1234567"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Ansprechpartner Mobil -->
          <b-col md="12">
            <b-form-group
              label="Mobil"
              label-for="register-ansprechpartner-mobil"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobil"
                vid="mobil"
              >
                <b-form-input
                  id="register-ansprechpartner-mobil"
                  v-model="customerData.Ansprechpartner[0].Mobil"
                  name="register-ansprechpartner-mobil"
                  :state="errors.length > 0 ? false : null"
                  placeholder="1234 1234567"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Ansprechpartner Email -->
          <b-col md="12">
            <b-form-group
              label="Email*"
              label-for="register-ansprechpartner-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="register-ansprechpartner-email"
                  v-model="customerData.Ansprechpartner[0].Email"
                  name="register-ansprechpartner-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="ansprechpartner@firma.de"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <hr />

        <b-card-title class="mb-1">Sonstiges</b-card-title>
        <!-- Bewertung -->
        <b-form-group label="Bewertung" label-for="register-bewertung">
          <b-form-radio-group
            id="register-bewertung"
            class="d-flex justify-content-between"
            v-model="customerData.Bewertung"
            :options="[
              { text: '0', value: 0 },
              { text: '1', value: 1 },
              { text: '2', value: 2 },
              { text: '3', value: 3 },
              { text: '4', value: 4 },
              { text: '5', value: 5 },
              { text: '6', value: 6 },
            ]"
            name="radio-validation"
          >
          </b-form-radio-group>
        </b-form-group>

        <!-- Ansprechpartner Zahlungsziel -->

        <b-form-group label="Zahlungsziel" label-for="register-zahlungsziel">
          <validation-provider
            #default="{ errors }"
            name="Zahlungsziel"
            vid="zahlungsziel"
          >
            <b-input-group append="Tage">
              <b-form-input
                id="register-zahlungsziel"
                v-model="customerData.Zahlungsziel"
                name="register-zahlungsziel"
                :state="errors.length > 0 ? false : null"
                placeholder="30"
                type="number"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Sonstiges -->
        <b-form-group
          label="Sonstige Informationen"
          label-for="register-sonstiges"
        >
          <b-form-textarea
            id="register-sonstiges"
            v-model="customerData.Sonstiges"
            rows="3"
            name="register-sonstiges"
            placeholder="Sonstige Informationen"
          />
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BCardTitle,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
  BFormRadioGroup,
  BInputGroup,
  BInputGroupAppend,
  BImg,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import de from 'vee-validate/dist/locale/de.json';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

localize('de', de);

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BCardTitle,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BFormFile,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      image: null,
      imageSrc: null,
      invoiceAddress: false,
      customerData: {},
      userData: {},
      // validation
      required,
      email,
      customerIdNotUnique: false,
      userIdNotUnique: false,
      emailNotUnique: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    hasImage() {
      return !!this.image;
    },
  },
  watch: {
    'customerData.Kundennummer': async function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const qs = require('qs');
        const query = qs.stringify(
          {
            filters: {
              Kundennummer: {
                $eq: this.customerData.Kundennummer,
              },
            },
            fields: ['id', 'Kundennummer'],
          },
          {
            encodeValuesOnly: true,
          }
        );

        await this.$http
          .get(`/kunden?${query}`)
          .then(response => {
            if (response.data.data.length > 0) {
              this.customerIdNotUnique = true;
            } else {
              this.customerIdNotUnique = false;
            }
          })
          .catch(err => console.log(err.response.data.error.message));
      }
    },
    'userData.username': async function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const qs = require('qs');
        const query = qs.stringify(
          {
            filters: {
              username: {
                $eq: this.userData.username,
              },
            },
            fields: ['id', 'username'],
          },
          {
            encodeValuesOnly: true,
          }
        );

        await this.$http
          .get(`/users?${query}`)
          .then(response => {
            if (response.data.length > 0) {
              this.userIdNotUnique = true;
            } else {
              this.userIdNotUnique = false;
            }
          })
          .catch(err => console.log(err.response.data.error.message));
      }
    },
    'userData.email': async function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const qs = require('qs');
        const query = qs.stringify(
          {
            filters: {
              email: {
                $eq: this.userData.email,
              },
            },
            fields: ['id', 'email'],
          },
          {
            encodeValuesOnly: true,
          }
        );

        await this.$http
          .get(`/users?${query}`)
          .then(response => {
            if (response.data.length > 0) {
              this.emailNotUnique = true;
            } else {
              this.emailNotUnique = false;
            }
          })
          .catch(err => console.log(err.response.data.error.message));
      }
    },
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  beforeMount() {
    this.initializeCustomerData();
  },
  methods: {
    async addCustomer(modalEvent) {
      modalEvent.preventDefault();

      this.$refs.registerCustomerForm.validate().then(async isValid => {
        if (!isValid) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Eingabe nicht korrekt!`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `Die Eingabe ist nicht korrekt. Bitte überprüfen Sie alle Felder und probieren Sie es noch einmal.`,
            },
          });
          return;
        }

        // Register User
        await this.$http
          .post('/users', {
            username: this.userData.username,
            email: this.userData.email,
            password: this.userData.password,
            role: 4,
          })
          .then(response => {
            const userID = response.data.id;
            return userID;
          })
          .then(async userID => {
            // Change User Class
            await this.$http
              .put(`/users/${userID}`, { role: 4 })
              .catch(err => console.log(err.response.data.error.message));

            // Register Image
            if (this.image) {
              const formData = new FormData();
              formData.append('field', 'avatar');
              formData.append('ref', 'plugin::users-permissions.user');
              formData.append('refId', userID);
              formData.append('files', this.image, this.image.name);

              await this.$http
                .post('/upload', formData)
                .catch(err => console.log(err.response.data.error.message));
            }

            // Register Customer
            this.customerData.User = +userID;
            if (!this.invoiceAddress) {
              delete this.customerData.Rechnungsanschrift;
            }
            await this.$http
              .post(`/kunden`, { data: this.customerData })
              .then(response => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Kunde angelegt`,
                    icon: 'SaveIcon',
                    variant: 'success',
                    text: `Der Kunde ${this.customerData.Firma} wurde erfolgreich gespeichert.`,
                  },
                });
                
                // Clear Customer Data
                this.initializeCustomerData();

                // Emit Event to Parent Component
                this.$emit('newCustomerAdded');

                // Close Modal
                this.$nextTick(() => {
                  this.$bvModal.hide(`modal-add-customer`);
                });

              })
              .catch(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Anlegen des Kunden fehlgeschlagen!`,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: `Der Kunde konnte nicht gespeichert werden. Bitte überprüfen Sie die Eingabe und probieren Sie es noch einmal.`,
                  },
                });
                console.log(err.response.data.error.message);
              });
          })
          .catch(err => {
            console.log(err.response.data.error.message);
          });
      });
    },

    // Clear Image
    clearImage() {
      this.image = null;
    },

    // Clear Customer Data
    initializeCustomerData() {
      this.userData = {
        username: null,
        email: null,
        password: null,
      };
      this.customerData = {
        Kundennummer: null,
        Firma: '',
        Webseite: '',
        USt_ID: '',
        Bewertung: 0,
        Sonstiges: '',
        Anschrift: {
          Strasse: '',
          Hausnummer: '',
          PLZ: null,
          Stadt: '',
        },
        Rechnungsanschrift: {},
        Kontakt: { Telefon: '', Mobil: '', Fax: '' },
        Ansprechpartner: [{}],
        User: null,
      };
      this.clearImage();
    },
  },
};
</script>

<style scoped>
.checkbox-group {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.checkbox-group .custom-checkbox {
  width: 10rem;
  margin-bottom: 0.5rem;
}
.avatar-preview {
  aspect-ratio: 1;
  object-fit: cover;
}
</style>
